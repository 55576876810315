import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'gatsby'; // Commented out since it’s unused
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .archive-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .projects-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    margin: 80px auto 0;
  }
`;

const StyledProject = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .project-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .project-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
    overflow: auto;
  }

  .project-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .project-links {
      display: flex;
      align-items: center;
      margin-right: -10px;
      color: var(--light-slate);

      a {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 5px 7px;

        &.external {
          svg {
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .project-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      position: static;
      height: 2.5em;
      line-height: 1.25;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .project-description {
    color: var(--light-slate);
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tech-list {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const Projects = () => {
  const [mediumPosts, setMediumPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  const fetchMediumPosts = async () => {
    try {
      const response = await fetch(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@farhan-labib',
      );
      const data = await response.json();

      if (data.status === 'ok') {
        const posts = data.items.map(item => ({
          title: item.title,
          link: item.link,
          description: `${item.description.replace(/<[^>]*>/g, '').substring(0, 150)  }...`,
          categories: item.categories,
          publishDate: new Date(item.pubDate).toLocaleDateString(),
        }));
        setMediumPosts(posts);
      }
    } catch (error) {
      console.error('Error fetching Medium posts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediumPosts();
  }, []);

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const firstSix = mediumPosts.slice(0, GRID_LIMIT);
  const postsToShow = showMore ? mediumPosts : firstSix;

  const postInner = post => (
    <div className="project-inner">
      <header>
        <div className="project-top">
          <div className="folder">
            <Icon name="Bookmark" />
          </div>
          <div className="project-links">
            <a
              href={post.link}
              aria-label="External Link"
              className="external"
              target="_blank"
              rel="noreferrer">
              <Icon name="External" />
            </a>
          </div>
        </div>

        <h3 className="project-title">
          <a href={post.link} target="_blank" rel="noreferrer">
            {post.title}
          </a>
        </h3>

        <div className="project-description">
          <p>{post.description}</p>
        </div>
      </header>

      <footer>
        {post.categories && (
          <ul className="project-tech-list">
            {post.categories.slice(0, 3).map((category, i) => (
              <li key={i}>{category}</li>
            ))}
          </ul>
        )}
      </footer>
    </div>
  );

  if (loading) {
    return (
      <StyledProjectsSection>
        <h2>Loading blog posts...</h2>
      </StyledProjectsSection>
    );
  }

  return (
    <StyledProjectsSection>
      <h2 ref={revealTitle}>Featured Blog Posts</h2>

      <a
        className="inline-link archive-link"
        href="https://medium.com/@farhan-labib"
        target="_blank"
        rel="noopener noreferrer"
        ref={revealArchiveLink}>
        view all posts
      </a>

      <ul className="projects-grid">
        {prefersReducedMotion ? (
          <>
            {postsToShow &&
              postsToShow.map((post, i) => (
                <StyledProject key={i}>{postInner(post)}</StyledProject>
              ))}
          </>
        ) : (
          <TransitionGroup component={null}>
            {postsToShow &&
              postsToShow.map((post, i) => (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledProject
                    key={i}
                    ref={el => (revealProjects.current[i] = el)}
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    {postInner(post)}
                  </StyledProject>
                </CSSTransition>
              ))}
          </TransitionGroup>
        )}
      </ul>

      {mediumPosts.length > GRID_LIMIT && (
        <button className="more-button" onClick={() => setShowMore(!showMore)}>
          Show {showMore ? 'Less' : 'More'}
        </button>
      )}
    </StyledProjectsSection>
  );
};

export default Projects;

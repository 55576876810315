import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledSkillsSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      display: block;
    }
  }
`;

const StyledSkillCategory = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }

  h3 {
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const Skills = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skillCategories = {
    'Testing Methodologies': [
      'Manual Testing',
      'Automated Testing',
      'Regression Testing',
      'Exploratory Testing',
      'API Testing',
      'Performance Testing',
      'Cross-Browser Testing',
      'Cross-Platform Testing',
    ],
    'Tools & Frameworks': [
      'Playwright',
      'k6',
      'Jest',
      'Supertest',
      'JMeter',
      'Jira',
      'Insomnia',
      'Proxyman',
      'HTTP Toolkit',
    ],
    'Technical Skills': [
      'JavaScript',
      'C',
      'MySQL',
      'Test Case Management',
      'Defect Tracking',
      'Quality Assurance',
      'Documentation',
    ],
  };

  return (
    <StyledSkillsSection id="skills" ref={revealContainer}>
      <h2 className="numbered-heading">Skills</h2>

      <div className="inner">
        {Object.entries(skillCategories).map(([category, skills]) => (
          <StyledSkillCategory key={category}>
            <h3>{category}</h3>
            <ul className="skills-list">
              {skills.map((skill, i) => (
                <li key={i}>{skill}</li>
              ))}
            </ul>
          </StyledSkillCategory>
        ))}
      </div>
    </StyledSkillsSection>
  );
};

export default Skills;
